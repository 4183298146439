import SubscriptionCards from './subscription/subscription_cards';

function Dispatcher() {
  const page = $('body').attr('data-page');

  if (!page) {
    return;
  }

  switch (page) {
    case 'subscriptions:show':
    case 'subscriptions:index':
      SubscriptionCards();
      break;
    default:
  }
}

$(() => new Dispatcher());
