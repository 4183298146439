import { extend } from 'vee-validate';
import { required, excluded } from 'vee-validate/dist/rules';
import { __ } from '~/javascripts/lib/locale';
import { noEmoji } from './rules/no_emoji';

export default function initValidation() {
  extend('noEmoji', noEmoji);
  extend('required', {
    ...required,
    message: __('{_field_} is required.'),
  });
  extend('excluded', {
    ...excluded,
  });
}
