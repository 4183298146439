<script>
import {
  GlIcon,
  GlNavItemDropdown,
  GlNav,
  GlDropdownItem,
  GlDropdownDivider,
  GlCollapse,
  GlCollapseToggleDirective,
} from '@gitlab/ui';
import queryString from 'query-string';
import LANGUAGE_SWITCH_IMG from '~/images/switch.png';
import { languageCode } from '~/javascripts/lib/locale';

export default {
  name: 'SwitchLanguage',
  components: {
    GlIcon,
    GlNavItemDropdown,
    GlNav,
    GlDropdownItem,
    GlDropdownDivider,
    GlCollapse,
  },
  directives: {
    GlCollapseToggle: GlCollapseToggleDirective,
  },
  computed: {
    showSwitchLanguage() {
      return gon.global.jh;
    },
    showReceipt() {
      return gon.global.jh;
    },
    currentLanguage() {
      return this.languageCode();
    },
  },
  methods: {
    languageCode,
    languageSwitchUrl(language = 'en') {
      try {
        const originSearchKeys = queryString.parse(window.location.search);
        const searchKeyToString = queryString.stringify({ ...originSearchKeys, locale: language });
        return `${window.location.pathname}?${searchKeyToString}`;
      } catch (e) {
        return window.location;
      }
    },
  },
  LANGUAGE_SWITCH_IMG,
};
</script>

<template>
  <div>
    <button
      v-gl-collapse-toggle.primary-navigation
      class="navbar-toggler"
      type="button"
      aria-controls="primary-navigation"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <gl-collapse id="primary-navigation" class="navbar-collapse">
      <gl-nav class="navbar-nav ml-auto" role="menubar">
        <gl-nav-item-dropdown
          v-if="showSwitchLanguage"
          toggle-class="gl-font-sm font-weight-bold d-flex align-items-center"
          right
        >
          <template #button-content>
            <span id="jh-i18n-btn" class="mr-1">
              <img
                :src="$options.LANGUAGE_SWITCH_IMG"
                alt=""
                style="width: 18px; vertical-align: middle"
              />
            </span>
            <gl-icon :size="12" name="chevron-lg-down" />
          </template>
          <gl-dropdown-item
            class="gl-font-base"
            :class="currentLanguage === 'zh-CN' ? 'gl-bg-gray-600' : ''"
            :href="languageSwitchUrl('zh-CN')"
          >
            <span>简体中文</span>
          </gl-dropdown-item>
          <gl-dropdown-divider />
          <gl-dropdown-item
            class="gl-font-base"
            :class="currentLanguage === 'en' ? 'gl-bg-gray-600' : ''"
            :href="languageSwitchUrl('en')"
          >
            <span>English</span>
          </gl-dropdown-item>
        </gl-nav-item-dropdown>
      </gl-nav>
    </gl-collapse>
  </div>
</template>
