import axios from '~/javascripts/lib/axios_utils';
import SubscriptionTransactionRedirector from './subscription_transaction_redirector';

function trackEvent(id, state, group) {
  if (!window.snowplow) return;

  const schemaUrl = 'iglu:com.gitlab/subscription_auto_renew/jsonschema/1-0-0';

  window.snowplow(
    'trackStructEvent',
    'subscription_card', // context
    'subscription_auto_renew', // action
    state, // label
    group, // property
    null, // value
    [{ schema: schemaUrl, data: { id } }],
  );
}

function updateSubscription(e, toggleState, group, callback) {
  e.preventDefault();

  const { id, transaction } = e.currentTarget.dataset;
  const state = transaction || (toggleState ? 'restored' : 'cancelled');

  trackEvent(id, state, group);

  return axios
    .put(`/subscriptions/${id}/auto_renew`, {
      auto_renew: (state === 'restored').toString(),
    })
    .then(() => {
      SubscriptionTransactionRedirector.success(id, state);
    })
    .finally(() => {
      if (callback) callback.call();
    });
}

export default function SubscriptionCards() {
  $(document).on('click', '.js-auto-renew', (e) => {
    $(e.target).prop('disabled', true);

    const callback = () => $(e.target).prop('disabled', false);

    updateSubscription(e, null, 'experiment', callback);
  });
}
