import Vue from 'vue';
import Translate from '~/javascripts/lib/translate';
import parseBoolean from '../lib/parse_boolean';
import NavBarApp from './components/nav_bar_app.vue';

Vue.use(Translate);
export default (el) => {
  if (!el) {
    return false;
  }

  const data = el.dataset || {};

  return new Vue({
    el,
    provide: {
      isSignedIn: parseBoolean(data.isSignedIn),
      hasSubscriptions: parseBoolean(data.hasSubscriptions),
      customerFullName: data.customerFullName,
      hasBillingAccount: parseBoolean(data.hasBillingAccount),
      accountDetailsPath: data.editCustomerRegistrationPath,
      contactUsUrl: data.contactUsUrl,
      subscriptionsPath: data.subscriptionsPath,
      invoicesPath: data.invoicesPath,
      paymentMethodsPath: data.paymentMethodsPath,
      signOutPath: data.signOutPath,
      signInPath: data.signInPath,
      displayNavigationItems: parseBoolean(data.displayNavigationItems),
    },
    render(createElement) {
      return createElement(NavBarApp);
    },
  });
};
