// Redirect users to a success page
export default class SubscriptionTransactionRedirector {
  static success(subscriptionIdOrName, transaction) {
    // We always fetch the parent as we also use this in an iFrame context.
    const $doc = window.parent.document;

    const planId = $('#plan_id', $doc).val();

    // We always expect a $dataAttribute when calling this service,
    // the only exception is when we pass the transaction string directly (e.g. auto_renew).
    // That's because this would be used in the context of "/subscriptions" page.
    const $dataAttribute = $('[data-transaction]', $doc);

    const isPurchase = $dataAttribute.data('purchase');
    const subscriptionId = subscriptionIdOrName;

    let successUrl = null;

    if (isPurchase) {
      successUrl = `/subscriptions/${subscriptionId}/success/${
        transaction || $dataAttribute.data('transaction')
      }`;
      if (planId) {
        successUrl += `?plan_id=${planId}`;
      }
    } else {
      // If it's not a purchase, no need to send the user to a standalone success page.
      // Instead, redirect to the regular subscription page.
      successUrl = `/subscriptions/${subscriptionId}?transaction=${transaction}`;
    }

    window.top.location.href = successUrl;
  }
}
